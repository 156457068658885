import * as React from "react"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"

import Layout from "../components/layout"
import ScheduleVisitForm from "../components/stagedScheduleVisitForm"
import { ScrollDownButton } from "../components/ui"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"

const DesignStudio: React.FC<PageProps> = ({
  location,
}: PageProps): JSX.Element => {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(ScrollToPlugin)

  const h1Ref = React.useRef<HTMLHeadingElement>()
  const h1bgRef = React.useRef<HTMLDivElement>()
  const scrollButtonRef = React.useRef<HTMLDivElement>()
  const underlineRef = React.useRef<HTMLDivElement>()
  const introTextRef = React.useRef<HTMLDivElement>()
  const part2Ref = React.useRef<HTMLDivElement>()
  const part2Layer0Ref = React.useRef<HTMLDivElement>()
  const part2Layer1Ref = React.useRef<HTMLDivElement>()
  const part2Layer2Ref = React.useRef<HTMLDivElement>()
  const part2Layer3Ref = React.useRef<HTMLDivElement>()
  const part3Ref = React.useRef<HTMLDivElement>()
  const clientImage1Ref = React.useRef<HTMLDivElement>()
  const clientpart2Layer0Ref = React.useRef<HTMLDivElement>()

  const brandsRef = React.useRef<HTMLDivElement>()
  const firstBrandRef = React.useRef<HTMLDivElement>()
  const lastBrandRef = React.useRef<HTMLDivElement>()

  React.useEffect(() => {
    const tl1 = gsap.timeline({ repeat: -1, repeatDelay: 0 })
    tl1.to(
      h1bgRef.current,
      { borderBottomRightRadius: `4px`, duration: 1.5, ease: `bounce.out` },
      0
    )
    tl1.to(
      h1bgRef.current,
      { borderBottomLeftRadius: `4px`, duration: 1.5, ease: `bounce.out` },
      1
    )
    tl1.to(
      h1bgRef.current,
      { borderTopRightRadius: `4px`, duration: 1.5, ease: `bounce.out` },
      2
    )
    tl1.to(
      h1bgRef.current,
      { borderTopLeftRadius: `4px`, duration: 1.5, ease: `bounce.out` },
      3
    )
    tl1.to(
      h1bgRef.current,
      {
        borderRadius: `100px`,
        rotate: 360,
        duration: 4,
        ease: `power4.inOut`,
      },
      5
    )

    const tl2 = gsap.timeline({
      scrollTrigger: {
        start: `top top+=72px`,
        trigger: introTextRef.current,
      },
    })
    tl2.to(
      underlineRef.current,
      {
        duration: 1,
        ease: `power4.in`,
        width: `85%`,
      },
      1
    )

    const h1bgRefObserver = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            tl1.play()
          } else {
            tl1.pause()
          }
        })
      },
      { threshold: 1.0 }
    )

    h1bgRefObserver.observe(h1bgRef.current)

    const tlPart2 = gsap
      .timeline({
        scrollTrigger: {
          start: `top bottom-=25%`,
          trigger: part2Ref.current,
        },
      })
      .from(part2Layer3Ref.current, { duration: 1, opacity: 0 }, 0)
      .from(part2Layer1Ref.current, { duration: 1, opacity: 0 }, 0.5)
      .from(part2Layer2Ref.current, { duration: 1, opacity: 0 }, 1)

    const tlPart3 = gsap
      .timeline({
        scrollTrigger: {
          start: `top bottom-=15%`,
          trigger: part3Ref.current,
        },
      })
      .from(
        clientImage1Ref.current,
        {
          duration: 1,
          ease: `power2.out`,
          opacity: 0,
          x: `-15%`,
        },
        0
      )
      .from(
        clientpart2Layer0Ref.current,
        {
          duration: 1,
          ease: `power2.out`,
          opacity: 0,
          x: `15%`,
        },
        0.5
      )

    gsap.from(scrollButtonRef.current, {
      delay: 1.5,
      duration: 1,
      ease: `power2.out`,
      opacity: 0,
    })

    const brandScrollerTL = gsap.timeline({
      scrollTrigger: {
        end: `top top+=20%`,
        scrub: 0.3,
        start: `bottom bottom`,
        trigger: brandsRef.current,
      },
    })

    brandScrollerTL.to(brandsRef.current, {
      scrollTo: { autoKill: true, x: `max` },
    })

    return () => {
      tl1.kill()
      tl2.kill()
      tlPart2.kill()
      tlPart3.kill()
      brandScrollerTL.kill()
    }
  }, [])

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Customize your new home in our Design Studio. Choose your flooring, fixtures, tiles, cabinetry, and more from Kohler, Marsh, StarMark, and other top brands.",
          image: `${location.origin}/sundog-design-studio-logo-wall-1.jpg`,
          imageAlt: `The interior of the Sundog Homes design studio`,
        }}
        title="Visit Our Asheville Design Studio | Sundog Homes"
        url={location.href}
      />

      {/* Part 1 */}
      <Box h="calc(100vh - 4.5rem)" pos="relative" w="100vw">
        <Box h="100%" left="0" pos="absolute" top="0" w="100%" zIndex={-1}>
          <StaticImage
            alt="The Sundog Homes design studio"
            className="squared"
            src="../images/sundog-design-studio-logo-wall-1.jpg"
            layout="fullWidth"
            style={{
              height: `calc(100vh - 4.5rem)`,
              width: `100vw`,
            }}
          />
        </Box>
        <Center h="100%" w="100%" zIndex={2}>
          <Box h="100%" pos="relative" w="100%" overflow="hidden">
            <Box
              bgGradient="linear(-45deg, purple.500, red.500)"
              borderRadius="100px"
              h="200px"
              left="calc(50vw - 100px)"
              maxW="100vw"
              pos="absolute"
              ref={h1bgRef}
              top="calc(50vh - 100px - 4.5rem)"
              w="200px"
              zIndex="base"
            />
            <Heading
              alignItems="center"
              color="white"
              display="flex"
              fontFamily="body"
              h={{ base: `80px`, md: `110px` }}
              justifyContent="center"
              left="50%"
              letterSpacing="-3px"
              pos="absolute"
              ref={h1Ref}
              size="3xl"
              textAlign="center"
              top={{
                base: `calc(50vh - 40px - 4.5rem)`,
                md: `calc(50vh - 55px - 4.5rem)`,
              }}
              transform="translateX(-50%)"
              w={{ base: `300px`, md: `500px` }}
            >
              studio: sundog
            </Heading>
          </Box>
        </Center>
        <Box
          bottom="50px"
          left="calc(50vw - 24px)"
          pos="absolute"
          ref={scrollButtonRef}
        >
          <ScrollDownButton offsetY={72} target={introTextRef} />
        </Box>
      </Box>
      <Flex
        align="center"
        bgGradient="linear(red.500, purple.500)"
        color="white"
        fontSize={[`2xl`, `2xl`, `4xl`]}
        fontWeight="light"
        minH="calc(100vh - 4.5rem)"
        justify="center"
        lineHeight={[`shorter`, `shorter`, `normal`]}
        pos="relative"
        py={12}
        ref={introTextRef}
        textAlign="center"
        w="100vw"
      >
        <Box>
          <Text mx="auto" w={{ base: `300px`, md: `500px` }}>
            We want our clients to feel as engaged as we do while we're
            collaborating on their new home.
          </Text>
          <br />
          <Text
            fontWeight="semibold"
            mx="auto"
            w={{ base: `300px`, md: `500px` }}
          >
            So we built Studio Sundog.
          </Text>
          <Box bg="blue.200" h="6px" ml="6.5%" ref={underlineRef} w="0px" />
          <br />
          <Text mx="auto" w={{ base: `300px`, md: `500px` }}>
            It's base camp throughout the entire build process. You'll come here
            to meet us for the very first time. You'll come here to make
            selections. You'll come here to meet with your build team during
            construction. And hopefully, when it's all said and done, you'll
            still come back every once in a while just to catch up and let us
            know how you’re doing.
          </Text>
        </Box>
      </Flex>

      {/* Part 2 */}
      <Box h="calc(100vh - 4.5rem)" pos="relative" ref={part2Ref} w="100vw">
        {/* layer 0: image */}
        <Box
          h="100%"
          left="0px"
          pos="absolute"
          ref={part2Layer0Ref}
          top="0px"
          w="100%"
        >
          <StaticImage
            alt="The Sundog Homes design studio"
            className="squared"
            src="../images/sundog-design-studio-client-2.jpg"
            layout="fullWidth"
            style={{ height: `100%`, width: `100%` }}
          />
        </Box>
        {/* layer 1: image overlay */}
        <Box
          bgGradient="linear(to-b, transparent 20%, black)"
          h="100%"
          left="0px"
          pos="absolute"
          ref={part2Layer1Ref}
          top="0px"
          w="100%"
        />
        {/* layer 2: copy */}
        <Flex
          align="center"
          color="white"
          flexDir="column"
          h="100%"
          justify="flex-end"
          left="0px"
          pos="absolute"
          ref={part2Layer2Ref}
          top="0px"
          w="100%"
        >
          <Heading
            as="h2"
            maxW="800px"
            size="3xl"
            w={{ base: `85%`, md: `90%` }}
          >
            Make Yourself at Home
          </Heading>
          <Text
            fontSize={{ md: `2xl`, lg: `3xl` }}
            maxW="800px"
            mt={{ base: 4, md: 8 }}
            mb={{ base: `15%`, md: `10%` }}
            w={{ base: `85%`, md: `90%` }}
          >
            Take a seat with our Design Coordinator, Brannon and begin making
            decisions on the interior and exterior of your new custom home.
            She’ll help you select your new home’s fixtures, floors, cabinets,
            colors, and more in the comfort of our design studio. We’ll work
            with you to build the house you’ve always dreamed of, room-by-room.
          </Text>
        </Flex>
        {/* layer 3: decorative border */}
        <Box
          borderColor="red.700"
          borderStyle="solid"
          borderWidth="2px"
          h="95%"
          w="95%"
          left="2.5%"
          ref={part2Layer3Ref}
          top="2.5%"
          pos="absolute"
          rounded="lg"
        />
      </Box>

      {/* Part 3 */}
      <Box bg="white" h="calc(100vh - 4.5rem)" w="100vw">
        <Stack
          direction={{ base: `column`, md: `row` }}
          h="100%"
          spacing="0px"
          ref={part3Ref}
          w="100%"
        >
          <Box
            h="100%"
            pb={{ base: 2, md: 4 }}
            pl={4}
            pr={{ base: 4, md: 2 }}
            pt={4}
            ref={clientImage1Ref}
            w={{ base: `100%`, md: `50%` }}
          >
            <StaticImage
              alt="The Sundog Homes design studio"
              className="squared"
              src="../images/sundog-design-studio-client-1.jpg"
              layout="fullWidth"
              style={{
                height: `100%`,
                width: `100%`,
              }}
            />
          </Box>
          <Box
            h="100%"
            pb={4}
            pl={{ base: 4, md: 2 }}
            pr={4}
            pt={{ base: 2, md: 4 }}
            ref={clientpart2Layer0Ref}
            w={{ base: `100%`, md: `50%` }}
          >
            <StaticImage
              alt="The Sundog Homes design studio"
              className="squared"
              src="../images/sundog-design-studio-bathroom-1.jpg"
              layout="fullWidth"
              style={{
                height: `100%`,
                width: `100%`,
              }}
            />
          </Box>
        </Stack>
      </Box>

      {/* Part 4 */}
      <Box mt={8} w="100vw">
        <Box maxW="95vw" mx="auto" pt={[2, 4]} textAlign="center" w="800px">
          <Text fontSize="2xl" fontWeight="bold" mb={2}>
            Featuring brands you know, and brands you can't wait to meet
          </Text>
        </Box>
        <Flex
          align="center"
          bg="gray.200"
          h="150px"
          justify="center"
          overflowX="hidden"
          pos="relative"
          w="100%"
        >
          <Stack
            align="center"
            className="invisiscroll"
            direction="row"
            maxW="100vw"
            overflowX="scroll"
            pos="relative"
            ref={brandsRef}
            spacing={[4, 8, 10]}
          >
            <Box m={2} ref={firstBrandRef}>
              <StaticImage
                alt="Kohler's logo"
                layout="fixed"
                src="../images/brands/kohler-logo.png"
                width={100}
              />
            </Box>
            <Box m={2}>
              <StaticImage
                alt="Schluter's logo"
                layout="fixed"
                src="../images/brands/schluter-logo.png"
                width={100}
              />
            </Box>
            <Box m={2}>
              <StaticImage
                alt="Kohler's logo"
                layout="fixed"
                src="../images/brands/daltile-vector-logo.svg"
                width={100}
              />
            </Box>
            <Box m={2}>
              <StaticImage
                alt="Marsh's logo"
                layout="fixed"
                src="../images/brands/jeld-wen-logo-vector.svg"
                width={100}
              />
            </Box>
            <Box m={2}>
              <StaticImage
                alt="Marsh's logo"
                layout="fixed"
                src="../images/brands/marsh-logo.png"
                width={100}
              />
            </Box>
            {/* <Box m={2}>
              <StaticImage
                alt="Schluter's logo"
                layout="fixed"
                src="../images/brands/schluter-logo.png"
                width={100}
              />
            </Box>
            <Box m={2}>
              <StaticImage
                alt="Kohler's logo"
                layout="fixed"
                src="../images/brands/kohler-logo.png"
                width={100}
              />
            </Box>
            <Box m={2}>
              <StaticImage
                alt="Marsh's logo"
                layout="fixed"
                src="../images/brands/marsh-logo.png"
                width={100}
              />
            </Box>
            <Box m={2} ref={lastBrandRef}>
              <StaticImage
                alt="Schluter's logo"
                layout="fixed"
                src="../images/brands/schluter-logo.png"
                width={100}
              />
            </Box> */}
          </Stack>
        </Flex>
      </Box>

      <Box bg="white" id="schedule" pos="relative" py={32} w="100vw">
        <Center
          flexDir="column"
          h="100%"
          maxW="95vw"
          mx="auto"
          px={{ base: 6, md: 0 }}
          textAlign="center"
          w="400px"
        >
          <Heading as="h2" fontSize={[`3xl`, `4xl`]}>
            Schedule Your Introductory Call
          </Heading>
          <Text fontSize="xl" mt={2}>
            Times are available Monday-Friday, between 9:00am and 4:00pm.
          </Text>
          <Box mt={6} w="100%">
            <ScheduleVisitForm
              buttonColor="teal"
              buttonText="Request Appointment"
              context={[]}
              eventLabel="Schedule Consult (Phone)"
            />
          </Box>
          <Alert
            colorScheme="gray"
            mt={8}
            rounded="md"
            shadow="base"
            status="info"
            w="100%"
          >
            <AlertIcon />
            <Box d="block" flex="1" textAlign="left">
              <AlertTitle>Need help?</AlertTitle>
              <AlertDescription>
                Give us a call during our office hours:
                <Link
                  color="red.600"
                  fontWeight="bold"
                  href="tel:8287745720"
                  ml={1}
                >
                  (828) 774-5720
                </Link>
              </AlertDescription>
            </Box>
          </Alert>
        </Center>
      </Box>
    </Layout>
  )
}

export default DesignStudio
